export const COLORS = {
  BLUE: '#2626cc',
  BLUELIGHT: '#2659ed',
  BLUEGRAY: '#7070ff',
  BLUEDARK: '#00008B',
  BLUEWHITE: '#cfcfff',
  DARKGRAY: '#161719',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  LIGHTBROWN: '#707070',
};
